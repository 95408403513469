.container {
  height: "100vh";
  width: "100vw";
}

@media (min-width: 1200px) {
  .container {
    margin-left: 190px;
  }
}

.toolbarLogo {
  margin-left: auto;
}

.loginButton {
  background: #18a3c9;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.75;
  letter-spacing: 0.02857em;
  text-transform: uppercase;
  min-width: 64px;
  padding: 2%;
  border-radius: 4px;
  color: #fff;
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%),
    0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
}

@media (min-width: 1199px) {
  .hidelg {
    display: none !important;
  }
}
